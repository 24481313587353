
class SoundKey
{
  constructor(context, buffer) {
    this.context = context;
    this.buffer  = buffer;
    this.source  = null;
  }

  start() {
    if ( ! this.source) {
      this.source = this.context.createBufferSource();
      this.source.buffer = this.buffer;
      this.source.connect(this.context.destination);
      this.source.onended = () => { this.source = null; };
      this.source.start();
    }
  }

  stop() {
    if (this.source) {
      this.source.stop();
    }
  }
}

async function loadSoundMap(from, to) {
  const context = new AudioContext();
  const map = new Map();
  
  for (let i = from; i <= to; i++) {
    const audioBuffer = await window.fetch('sounds/' + i + '.mp3')
      .then(response => response.arrayBuffer())
      .then(arrayBuffer => context.decodeAudioData(arrayBuffer));

    map.set(i, new SoundKey(context, audioBuffer));
  }

  return map;
};

export default loadSoundMap;
