import React, { useReducer, useState, useCallback, useEffect } from 'react';
import './App.css';

import * as reactPiano from 'react-piano';
import 'react-piano/dist/styles.css';

import Recorder from './Recorder.js';
import loadSoundMap from './loadSoundMap.js';

// reducer for state of pressed keys
function keysReducer(state, action) {
  switch (action.type) {
    case 'on':
      return [...state, action.key];
    case 'off':
      return state.filter(key => key !== action.key);
    default:
      throw new Error();
  }
}

function App() {
  // define range of keys (attention, you also need corrosponding .mp3 files in ~/public/sounds)
  const firstNote = 60;
  const lastNote  = 71;
  // configure to use the computer keyboard as a piano keyboard
  const keyboardShortcuts = reactPiano.KeyboardShortcuts.create({
    firstNote: firstNote,
    lastNote: lastNote,
    keyboardConfig: reactPiano.KeyboardShortcuts.HOME_ROW,
  });

  const [activeKeys, dispatchKeysState] = useReducer(keysReducer, []);
  // holds all songs
  const [songs , setSongs ] = useState([{track: [
    /* 
    simple custom audio format for polyphone tracks with timing
    gap: means the milliseconds between two audio events
    key: is the note to play (midi)
    dur: how long the sound should play
    */
    {gap: 0, key: 60, dur: 2000},
    {gap: 1000, key: 62, dur: 2000},
    {gap: 1500, key: 63, dur: 5000},
  ]}]);
  // holder of webAudio nodes to play
  const [soundMap, setSoundMap] = useState(null);
  // record songs
  const [recorder, setRecorder] = useState(null);
  // player, directly connected to the (visual) keyboard component
  const playNotes = useCallback((keys) => {
    let step;
    step = (i) => {
      if (keys[i]) {
        // set timeout for time between two notes
        setTimeout(() => {
          // "start" note 
          dispatchKeysState({...keys[i], type: 'on'});

          // trigger next
          step(i+1);

          // set duration/end of note
          setTimeout(() => dispatchKeysState({...keys[i], type: 'off'}), keys[i].dur);
        }, keys[i].gap);
      }
      // TODO return a stopper callback?
    };
    // init the player
    step(0);
  });
  const onStartRecord = useCallback(() => {
    setRecorder(new Recorder());
  });
  const onStopRecord = useCallback(() => {
    recorder.finish();
    setSongs([...songs, {track: recorder.getSong()}]);
    setRecorder(null);
  });

  useEffect(() => {
    loadSoundMap(firstNote, lastNote).then(setSoundMap);
  }, []);

  return (
    <div className="App">

      <div>{/* div needed because of strange outer container from the Piano */}
        <reactPiano.Piano
          noteRange={{ first: firstNote, last: lastNote }}
          playNote={(key) => { soundMap.get(key).start(); if (recorder) recorder.recordNoteStart(key); }}
          stopNote={(key) => { soundMap.get(key).stop (); if (recorder) recorder.recordNoteStop(key); }}
          width={800}
          keyboardShortcuts={keyboardShortcuts}
          activeNotes={activeKeys}
          disabled={!soundMap}
        />
        {recorder ? <button className="big" onClick={onStopRecord}>Stop</button> : <button className="big" onClick={onStartRecord}>Record</button>}
      </div>
      <div className="song-list">
          {songs.map((song, i) => { return (<p key={i}><input placeholder="Type name …" /> <button onClick={()=>{ playNotes(song.track) }}>▶ Play</button><code>{JSON.stringify(song.track)}</code></p>)})}
      </div>
    </div>
  );
}

export default App;
