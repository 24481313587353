// to record notes/keys
class Recorder
{
  constructor() {
    this.pressedKeys = new Map();
    this.track        = [];
    this.lastTs      = null;
  }

  // after piano key press, finish the note/key in the stream
  finishKeyEvent(ts, keyEvent) {
    // set duration
    keyEvent.dur = ts - keyEvent.ts;
    delete keyEvent.ts;
  };


  recordNoteStart(key) {
    if ( ! key) throw new Error('No key for note start');
    // timestramp for calculation of duration and gap
    let ts = Date.now();
    if (this.lastTs === null) {
      this.lastTs = ts;
    }

    let keyEvent = {
      gap: ts - this.lastTs,
      key,
      ts // will be deleted
    };
    // manage which keys are pressed
    this.pressedKeys.set(key, keyEvent);
    // an event, stored in the "song"
    this.track.push(keyEvent);
    this.lastTs = ts;
  };

  recordNoteStop(key) {
    this.finishKeyEvent(Date.now(), this.pressedKeys.get(key));
    // manage which keys are pressed
    this.pressedKeys.delete(key)
  };

  // finish whole song
  finish() {
    let ts = Date.now();
    // set duration of unfinished notes
    for (let [ , keyEvent] of this.pressedKeys) {
      this.finishKeyEvent(ts, keyEvent);
    }
  }

  getSong() {
    return this.track;
  }
}

export default Recorder;
